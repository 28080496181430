import { Bar, GradientBar } from './styles';

const Topbar = () => {
  return (
    <>
      <Bar />
      <GradientBar />
    </>
  );
};

export default Topbar;
